import React, { Fragment, useEffect, useState } from "react";
import Title from "../../../text/title";
import LinkText from "../../../text/linkText";
import Toaster from "../../../common/Toaster";
import { Formik } from "formik";
import RoundedBtn from "../../../button/roundedBtn";
import "./style.scss";
import OptionCard from "../../optionCard";
import Input from "../../../input";
import NavRight from "../../../button/navRight";
import NavLeft from "../../../button/navLeft";
import { Link, navigate } from "gatsby";
import { connect } from "react-redux";
// import { addNewBrand as addBrand } from "../../../../actions/brand/action";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../../static/images/common/customer-form-lottie.json";
import InputWithIcon from "../../../input/inputWithIcon";
import { forgotPasswordHandler } from "../../../../actions/user/action";

const ForgotPassword = ({ forgotPasswordHandler, brand }) => {
  const [isMobile, setMobile] = useState(false);
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const [toastrType, setToastrType] = useState("success");
  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
    
  }, []);


  return (
    <Fragment>
      {/* <div className="whole-page-loader">
        <img src="/images/common/loader.svg" />
      </div> */}
      <Toaster refresh={refresh} type={toastrType} message={toastrMsg} />
      <div className="onboarding row reset">
        <div className="top">
          <img src="/images/common/plus_new_logo.svg" />
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12 r-image">
          <div className="d-flex justify-content-center margin-top-img lottie">
            {!isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 200, height: 200, margin: "auto" }}
              />
            )}
            {isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 120, height: 120, margin: "auto" }}
              />
            )}
           
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12  step-content">
        <Formik
            initialValues={{email:''}}
            enableReinitialze= {true}
            validate={(values) => {
              const errors = {};

              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              // if (!values.password) {
              //   errors.password = "Required";
              // } 
              
              return errors;
            }}
            onSubmit={async (values) => {
              
              if(!loading){
                setLoading(true)
                
                var user = await forgotPasswordHandler({ email:values.email });
                if (user && !user.error) {
                  setToastrMsg("Password has been reset");
                  setToastrType('success')
                  doRefresh((prev) => prev + 1);
                  setLoading(false)
                  navigate("/login");
                } else {
                  setToastrMsg(user.errorData);
                  setToastrType('error')
                  doRefresh((prev) => prev + 1);
                  setLoading(false)
                }
              }
              
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit} className="form-register">
                <>
                  <div className="form">
                    <div className="reset-title">Re-set your password</div>
                    <div className="ip-group">
                      <InputWithIcon
                        name="email"
                        type="text"
                        placeholder="Email"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        icon="/images/common/mail.svg"
                      />
                      {errors.email && touched.email && (
                        <p className="error-class">{errors.email}</p>
                      )}
                    </div>
                   
                  </div>
                  <div className="row back-buttons">
                  
                    <div className="d-flex nav-icons">
                    <div className="nav-conatiner">
                    
                    <button 
                    onClick={() => handleSubmit()}
                    >Continue</button>
                    
                  </div>
                    </div>

                    {/* <div className="col-md-6 col-lg-6 col-sm-6 col-12"> </div>
                    <div className="col-md-6 col-lg-6 col-sm-6 col-12 step3-btn">
                      <RoundedBtn
                        background="#31FFEA"
                        color="#004E93"
                        tag="Continue"
                        type="submit"
                      />
                    </div> */}
                  </div>
                </>
              </form>
            )}
            </Formik>
         
        </div>
        {/* <div className={!isMobile ? "row" : ""}>
          <div className="col-12 col-md-6 col-sm-6 col-xl-6 to-right hpx"></div>
          <div className="col-12 col-md-6 col-sm-6 col-xl-6 ">
            <div className="nav-conatiner">
              <div>
              <button>Continue</button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ brand }) => ({
  brand:brand
});

export default connect(mapStateToProps, { forgotPasswordHandler })(ForgotPassword);
