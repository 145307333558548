import React from 'react'
import ForgotPassword from "../components/cards/onboardingCard/forgotPassword";

const forgotPassword = () => {
  return (
    <div className="bg">
      <div className="mid-card card-4">
        <ForgotPassword
        />
      </div>
    </div>
  );
}

export default forgotPassword;